import { list } from "~/lib/api/productionProductionMethods";
import { keepPreviousData } from "@tanstack/vue-query";
import { useCursorPaginationControls } from "@smakecloud/smake-use";

export const useProductionProductionStore = defineStore(
    "productionProductionStore",
    () => {
        const searchPageVisited = ref<boolean>(false);
        const disableSearchWatcher = ref<boolean>(false);

        const currentCursor = ref<string>();
        const searchString = ref<string>();
        const perPage = ref<number>(10);

        const queryKey = [
            "productionApi.getProductionProductionMethods",
            currentCursor,
            searchString,
            perPage,
        ];

        const {
            isFetching,
            isError,
            data: productionProductionMethods,
            error,
            refetch,
        } = useQuery({
            queryKey: queryKey,
            queryFn: () =>
                list({
                    cursor: currentCursor.value,
                    filter: {
                        q: searchString.value,
                    },
                    perPage: perPage.value,
                }),
            placeholderData: keepPreviousData,
        });

        const changeSearch = (search?: string) => {
            currentCursor.value = undefined;
            searchString.value = search ?? "";
        };

        return {
            searchPageVisited,
            productionProductionMethods,
            isFetching,
            isError,
            error,
            changeSearch,
            searchString,
            currentCursor,
            ...useCursorPaginationControls(
                productionProductionMethods,
                currentCursor,
            ),
            disableSearchWatcher,
            perPage,
            refetch,
        };
    },
);
